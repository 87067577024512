import React from "react";
import {FormattedMessage} from "react-intl";

const NotFound = () => {
  return (
    <div className="container">
      <div className="text-center">
        <FormattedMessage id="error.page.not.found" />
      </div>
    </div>
  );
};

export default NotFound;
